import React from 'react';
import styled from 'styled-components';

// import DifficultySwitch from './DifficultySwitch';

export default ({ onStart, onDifficultyChange, hard, finalTime, completed }) => (
    <StartScreen>
        { finalTime && completed && <Score>You finished in { finalTime }</Score> }
        <h1>M@C-SOFTINS</h1>
        <p className="mesage-center">Bienvenidos Al juego navideño gana premios y cupones de descuento</p>
        <Controls>
            <StartBtn onClick={onStart}>Play</StartBtn>
            {/*<DifficultySwitch*/}
                {/*hard={hard}*/}
                {/*onChange={onDifficultyChange}*/}
            {/*/>*/}
        </Controls>
        <div className="sc-bZQynM lbYGtt1">
          <button className="kvlNIS2">Canjear puntos</button>
        </div>
    </StartScreen>
);

const StartScreen = styled.div`
    padding-top: 125px;

    h1 {
        margin: 0 auto 45px;
        font-size: 57px;
        line-height: 110%;
        text-transform: uppercase;
        text-align: center;
        max-width: 450px;
        color: #333;
        font-weight: 900;
    }
    .mesage-center{
      text-align: center !important;
    }
    .kvlNIS2{
      width: 100%;
      padding: 12px;
      border: 0;
      background: #5FB023;
      color: #efefef;
      font-size: 26px;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 700;
      border-radius: 15px;
      -webkit-transition: .3s;
      transition: .3s;
    }
    .lbYGtt1{
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      max-width: 330px;
      margin: 0 auto;
      margin-top:+2%;
    }
` ;

const Controls = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 130px;
    margin: 0 auto;
`;

const StartBtn = styled.button`
    width: 100%;
    padding: 12px;
    border: 0;
    background: #5FB023;
    color: #efefef;
    font-size: 26px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 15px;
    transition: .3s;

    &:hover {
        background: #5fbf17;
    }
`;

const Score = styled.p`
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 32px;
    margin: 0;
`;
